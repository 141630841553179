import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import imagemap from "../assets/images/contacts/mebek_design_map.png"
import Layout from "../components/layout"
import Metadata from "../components/metadata"

const Contacts = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <Metadata title={intl.formatMessage({ id: "contacts.title" })} />
      <h1 className="d-flex m-2 p-3 justify-content-center align-items-center">
        {intl.formatMessage({ id: "contacts.title" })}
      </h1>
      <hr />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <img src={imagemap} alt="imagemap" style={{ maxWidth: "100%" }} />
            <div className="row py-5 text-align-center">
              <div className="col">
                <h4>{intl.formatMessage({ id: "contacts.adres_title" })}</h4>
                <p>{intl.formatMessage({ id: "contacts.adres" })}</p>
              </div>
              <div className="col">
                <h4>{intl.formatMessage({ id: "contacts.email" })}</h4>
                <p><a href={`mailto:`+intl.formatMessage({ id: "contacts.p_center" })}>{intl.formatMessage({ id: "contacts.p_center" })}</a></p>
              </div>
              <div className="col">
                <h4>{intl.formatMessage({ id: "contacts.telefoni" })}</h4>
                <ul>
                  <li><a href={`callto:` + intl.formatMessage({ id: "contacts.nomer_1" })}>{intl.formatMessage({ id: "contacts.nomer_1" })}</a></li>
                  <li><a href={`callto:` + intl.formatMessage({ id: "contacts.nomer_2" })}>{intl.formatMessage({ id: "contacts.nomer_2" })}</a></li>
                  <li><a href={`callto:` + intl.formatMessage({ id: "contacts.nomer_3" })}>{intl.formatMessage({ id: "contacts.nomer_3" })}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contacts
